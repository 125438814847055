
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzBox from "@/components/Box.vue";
  import { namespace } from "vuex-class";
  const auth = namespace("auth");

  @Component({
    components: { LzButton, LzBox }
  })
  export default class Home extends Vue {
    @auth.State("firstName")
    public firstName!: string;

    tools = {
      web: {
        title: this.$t("user.home.tools.web"),
        icon: "device-laptop",
        route: "web/public"
      },
      shop: {
        title: this.$t("user.home.tools.shop"),
        icon: "building-store",
        route: "shop/read"
      },
      events: {
        title: this.$t("user.home.tools.events"),
        icon: "calendar",
        route: "events/read"
      },
      // blog: {
      //   title: this.$t("user.home.tools.blog"),
      //   icon: "calendar",
      //   route: "blog/read"
      // },
      // reviews: {
      //   title: this.$t("user.home.tools.reviews"),
      //   icon: "clipboard-text",
      //   route: "web/public#reviews"
      // },
      bookings: {
        title: this.$t("user.home.tools.bookings"),
        icon: "address-book",
        route: "web/public#bookings"
      },
      projects: {
        title: this.$t("user.home.tools.projects"),
        icon: "clipboard-text",
        route: "projects/read"
      },
      services: {
        title: this.$t("user.home.tools.services"),
        icon: "file-like",
        route: "services/read"
      }
    } as const;

    capitalizeFirstLetter(str: string) {
      return str.charAt(0).toUpperCase() + str.substring(1);
    }

    redirectTo(path: string) {
      this.$router.push({ path: `/${path}` });
    }
  }
